import React, { useCallback, useEffect, useState } from 'react';
import Header from "../../components/Headers/Header";
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    FormGroup,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Label,
    Button,
} from "reactstrap";
import Dataservices from "../../services/requestApi";
import {message} from "antd";
import { City, State } from "country-state-city";
import './CreateOrder.css';

function CreateOrder() {
    const allState = State.getStatesOfCountry("IN");
    const [allCity, setAllCity] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [gstTotal, setGstTotal] = useState(0);
    const [amount, setAmount] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [empty, setEmpty] = useState(false);
    const [ready, setReady] = useState(false);
    const [mounted, setMounted] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [distributors, setDistributors] =useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [billingInfo, setBillingInfo] = useState({
        contact_name: '',
        contact_phone: '',
        email: '',
        state: '',
        city: '',
        zip: '',
        contact_address: '',
        distributor_id: '',
        txnId: '',
        amount: '',
        total_amount: '',
        quantity: '',
    });
    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setBillingInfo({ ...billingInfo, [name]: value });
    };

    const getAllProduct = useCallback(async () => {
        try {
            const [resProduct] = await Promise.all([Dataservices.ProductAll()]);
            // if (mounted) {
                setAllProducts(resProduct.data.data);
                if (!resProduct.data.data.length > 0) {
                    setEmpty(true);
                }
                setReady(true);

        } catch (e) {
            console.log(e);
        }
    }, []);

    const getDistributors = async () => {
        try {
            const resDistributors = await Dataservices.getAllDistributors();
            setDistributors(resDistributors.data.Distributor);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getAllProduct();
        getDistributors();
        return () => {
            getDistributors();
            getAllProduct();
            // setMounted(false);
        };
    }, [getAllProduct]);

    const handleProductChange = (index, field, value) => {
        const updatedProducts = [...products];
        updatedProducts[index][field] = value;

        if (field === 'product') {
            const selectedProduct = allProducts.find(
                (product) => product._id === value
            );

            if (selectedProduct) {
                updatedProducts[index].price = selectedProduct.price;
                updatedProducts[index].gst = selectedProduct.gst;
            }
            if (selectedProduct?.variants[0] && selectedProduct?.variants[0]?.size && selectedProduct?.variants[0]?.size.includes(',')) {
                setSizes(selectedProduct?.variants[0].size.split(','));
            } else {
                updatedProducts[index].size = selectedProduct?.size;
            }
        }

        if (field === 'quantity') {
            updatedProducts[index].quantity = parseInt(value);
        }

        const baseTotal = updatedProducts[index].price * updatedProducts[index].quantity;
        const gstAmount = (baseTotal * updatedProducts[index].gst)/100;
        updatedProducts[index].gst_amount = gstAmount;
        updatedProducts[index].amount = baseTotal;
        updatedProducts[index].total_amount = baseTotal + gstAmount;

        setProducts(updatedProducts);
        calculateTotal(updatedProducts);
    };

    const addProduct = () => {
        const newProducts = [
            ...products,
            { product: '', size: '', quantity: 1, gst_amount: 0, gst: 0, amount: 0, total_amount: 0 }
        ];
        setProducts(newProducts);
        updateBillingInfo(newProducts);
    };

    const calculateTotal = (updatedProducts) => {
        const grandTotal = updatedProducts.reduce((acc, product) => acc + product.total_amount, 0);
        const grandGstTotal = updatedProducts.reduce((acc, product) => acc + product.gst_amount, 0);
        const product_amount = updatedProducts.reduce((acc, product) => acc + product.amount, 0);
        setAmount(product_amount);
        setGstTotal(grandGstTotal);
        setTotal(grandTotal);
    };

    const updateBillingInfo = (updatedProducts) => {
        setBillingInfo((prev) => ({
            ...prev,
            product_data: updatedProducts  // Update product_data in billingInfo
        }));
    };

    const handleSubmit = async () => {
        try {
            const Data = new FormData();
            for (let item in billingInfo) {
                if (billingInfo[item]) {
                    Data.append(item, billingInfo[item]);
                }
            }
            Data.append('total_amount', total);
            Data.append('amount', amount);
            Data.append('txnId', Math.random().toString(36).substr(2, 9));

            let total_quantity = 0;
            products.forEach((product, index) => {
                total_quantity += product.quantity;
                Data.append(`products[${index}][product]`, product.product);
                Data.append(`products[${index}][size]`, product.size || '');
                Data.append(`products[${index}][quantity]`, product.quantity);
                Data.append(`products[${index}][price]`, product.price);
                Data.append(`products[${index}][gst]`, product.gst);
                Data.append(`products[${index}][gst_amount]`, product.gst_amount);
                Data.append(`products[${index}][amount]`, product.price);
                Data.append(`products[${index}][total_amount]`, product.total_amount);
            });

            Data.append('quantity', total_quantity);
            console.log([...Data]);
            console.log(Data);
            const res = await Dataservices.createOrderManually(Data);
            console.log(res.data);
            if (res?.data?.status_code === 200) {
                message.success(res.data.message);
                setReady(false);
            } else {
                setReady(false);
                message.error("Something Went Wrong");
            }
        } catch (e) {
            console.log(e.response.data);
            if (e.response.data) {
                message.success(e.response.data.message);
                setReady(false);
            }
        }
    }

    const HandleStateChange = (e) => {
        const { name, value } = e.target;
        setBillingInfo({ ...billingInfo, [name]: value });
        const allState = State.getStatesOfCountry("IN");
        const findStateCode = allState.find((item) => item?.name === value);
        const city = City.getCitiesOfState("IN", findStateCode?.isoCode);
        setAllCity(city);
    };

    const filteredDistributors = distributors.filter(distributor =>
        distributor.phone.includes(searchQuery)
    );

    const deleteProduct = (productId) => {
        const updatedProducts = products.filter(product => product.product !== productId);
        setProducts(updatedProducts);
        calculateTotal(updatedProducts);
        updateBillingInfo(updatedProducts);
    };

    return (
        <>
            <Header show={false}/>
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="shadow mt-4">
                            <CardBody>
                                <div style={{display: 'flex'}}>
                                    <div>
                                        <h2>Billing Address</h2>
                                        <FormGroup>
                                            <Label for="distributor">Distributor</Label>
                                            <Input
                                                type="text"
                                                placeholder="Search by phone number..."
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            <Input type="select" name="distributor_id" id="distributor"
                                                   value={billingInfo.distributor_id} onChange={handleBillingChange}>
                                                <option>Choose Distributor...</option>
                                                {filteredDistributors.map(distributor => (
                                                    <option key={distributor._id} value={distributor._id}>
                                                        {distributor.phone}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input type="text" name="contact_name" id="name" value={billingInfo.contact_name}
                                                   onChange={handleBillingChange} placeholder="Enter your name"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="phone">Phone Number</Label>
                                            <Input type="text" name="contact_phone" id="phone" value={billingInfo.contact_phone}
                                                   onChange={handleBillingChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input type="email" name="email" id="email" value={billingInfo.email}
                                                   onChange={handleBillingChange} placeholder="you@example.com"/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="state">State</Label>
                                            <Input type="select" name="state" id="state" value={billingInfo.state}
                                                   onChange={(e) => {
                                                       HandleStateChange(e);
                                                   }}>
                                                <option>Choose...</option>
                                                {allState?.map((item) => (
                                                    <option value={item?.name} key={item.isoCode}>
                                                        {item?.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="city">City</Label>
                                            <Input type="select" name="city" id="city" value={billingInfo.city}
                                                   onChange={(e) => {
                                                       setBillingInfo({
                                                           ...billingInfo,
                                                           city: e.target.value,
                                                       });
                                                   }}>
                                                <option>Choose...</option>
                                                {allCity?.map((item, index) => (
                                                    <option value={item?.name} key={index + 1}>
                                                        {item?.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="zip">Zip</Label>
                                            <Input type="text" name="zip" id="zip" value={billingInfo.zip}
                                                   onChange={handleBillingChange}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="address">Address</Label>
                                            <Input type="textarea" name="contact_address" id="address"
                                                   value={billingInfo.contact_address} onChange={handleBillingChange}/>
                                        </FormGroup>
                                    </div>
                                    <div style={{marginLeft: '20px', width: '70%'}}>
                                        <h2>Create Order</h2>
                                        <table className="table-bordered">
                                            <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Size</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>GST (%)</th>
                                                <th>GST Amount</th>
                                                <th>Total</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {products.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <select
                                                            value={item.product}
                                                            onChange={(e) => handleProductChange(index, 'product', e.target.value)}
                                                        >
                                                            <option value="">Select Product</option>
                                                            {allProducts.map((product) => (
                                                                <option key={product._id} value={product._id}>
                                                                    {product.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {item.size ? item.size :
                                                            <select
                                                                value={item.size}
                                                                onChange={(e) => handleProductChange(index, 'size', e.target.value)}
                                                            >
                                                                <option value="">Select Size</option>
                                                                {sizes.length > 0 && sizes.map((size, i) => (
                                                                    <option key={i} value={size}>
                                                                        {size}
                                                                    </option>
                                                                ))}
                                                            </select>}
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={item.quantity}
                                                            onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                                                        >
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((qty) => (
                                                                <option key={qty} value={qty}>
                                                                    {qty}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>{item.amount.toFixed(2)}</td>
                                                    <td>{item.gst.toFixed(2)}</td>
                                                    <td>{item.gst_amount.toFixed(2)}</td>
                                                    <td>{item.total_amount.toFixed(2)}</td>
                                                    <td>
                                                        <Button
                                                            color="danger"
                                                            onClick={() => deleteProduct(item.product)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </td>

                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <Button color="dark" onClick={addProduct} style={{marginBottom: '20px'}}>Add
                                            Product</Button>
                                        <hr/>
                                        <h3>Grand GST Total: {gstTotal.toFixed(2)}</h3>
                                        <h3>Grand Total: {total.toFixed(2)}</h3>
                                    </div>
                                </div>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <Button color="dark"
                                            onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CreateOrder;
